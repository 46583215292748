import React from 'react'
import { connect } from 'react-redux'
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official'
// Group Debrief
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import GroupDebrief from './GroupDebrief';

HC_more(Highcharts);

class HistoryChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            group: 0,
            order: 0,
            squares: this.props.squares || false,
            group_modal: null
        }
        // Bindings 
        this.setGroup           = this.setGroup.bind(this)
        this.setOffer           = this.setOffer.bind(this)
        this.handleGroupChange  = this.handleGroupChange.bind(this)
        this.handleChange       = this.handleChange.bind(this)
        this.setGroupModal      = this.setGroupModal.bind(this)
    }

    componentDidUpdate(prevProps){
        if( prevProps.squares !== this.props.squares ){
            this.setState({ squares: this.props.squares })
        }
    }

    setGroup(v){
        const { dances } = this.props
        let value = v
        if( v < 0 )
            value = dances.length
        if( v > dances.length )
            value = 0
        this.setState({ group: value, order: 0 })
    }

    setOffer(v){
        const { group } = this.state
        const { dances } = this.props
        const maxOffers = dances[group-1] ? dances[group-1].data.length : 0
        let value = v
        if( v < 0 )
            value = dances.length
        if( v > maxOffers )
            value = 0
        this.setState({ order: value })
    }

    handleGroupChange(e){
        const value = e.target.value
        if( value.length > 0 )
            this.setState({ group: parseInt(value), order: 0 })
    }

    handleChange(e){
        const n  = e.target.name;
        const v  = e.target.value;
        let data = {}
        data[n]  = v;
        this.setState( data );
    }

    setGroupModal( gindex ){
        const { game } = this.props
        const group_modal = game.data ? game.data.groups[gindex] : null
        this.setState({ group_modal })
    }

    render() {

        const { group, order, squares } = this.state
        const { dances, popup } = this.props
        const self = this

        let series = dances[group-1] ? [dances[group-1]] : dances
        const maxOffers = dances[group-1] ? dances[group-1].data.length : 0
        series = JSON.parse(JSON.stringify(series))
        series = series.map( s => {
            s.data = s.data[order-1] ? [s.data[order-1]] : []
            return s;
        })

        // console.log([...series])

        return <div style={{ height: '100%' }}>

            { dances.length > 1 && <div className="clearfix mb-3 row">
                <div className="col-2">

                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <button className="btn btn-primary" onClick={ () => this.setGroup( group-1 ) }>Prev</button>
                        </div>
                        <input type="text" className="form-control text-center" value={ group === 0 ? 'Scroll Dyads' : 'Dyad ' + group } placeholder="#" />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={ () => this.setGroup( group+1 ) }>Next</button>
                        </div>
                    </div>

                </div>
                { series.length === 1 && <div className="col-2 border-left">
                    <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                            <button className="btn btn-primary" onClick={ () => this.setOffer( order-1 ) }>Prev</button>
                        </div>
                        <input type="text" className="form-control text-center" value={ order === 0 ? ( maxOffers > 0 ? 'Scroll Offers' : 'No offers') : 'Offer ' + order } placeholder="#" />
                        <div className="input-group-append">
                            <button className="btn btn-primary" onClick={ () => this.setOffer( order+1 ) }>Next</button>
                        </div>
                    </div>
                    {/* // <input name="order" type="range" className="mt-0" min="1" max={maxOffers} value={ this.state.order } onChange={ this.handleChange } /> */}
                </div> }
            </div> }

            <HighchartsReact
                containerProps={{ style: { height: "450px" } }}
                highcharts={ Highcharts }
                options={{ 
                    title: { text: '' },
                    chart: { 
                        type: 'scatter',
                        events: {
                            load: function() {
                                var chart = this;
                                let distX1 = this.xAxis[0].toPixels(4);
                                let distX2 = this.xAxis[0].toPixels(15);
                                chart.renderer.text('Unacceptable: BOTH', distX1, 340)
                                .attr({ zIndex: 3, fill: '#FFF' })
                                .add();
                                chart.renderer.text('Unacceptable: STRUMR', distX1, 200)
                                .attr({ zIndex: 3, fill: '#333' })
                                .add();
                                chart.renderer.text('Unacceptable: Lennigan´s', distX2, 340)
                                .attr({ zIndex: 3, fill: '#333' })
                                .add();
                            }
                        }
                    },
                    credits: { enabled: false },
                    legend: { enabled: false },
                    xAxis: {
                        title: { text: 'Total # of Stands' },
                        allowDecimals: false,
                        min: 0,
                        minRange: 20,
                        // categories: ['Issue by Issue', 'Package Offer', 'MESOs', 'Finished Negotiation'],
                    },
                    yAxis: {
                        title: { text: '# of Stands in Band Area' },
                        allowDecimals: false,
                        min: 0,
                        minRange: 12
                    },
                    tooltip: {
                        formatter: function () {
                            const p = this.point
                            return `<b>${this.series.name}</b><br/><b>Band: ${p.y}</b><br/><b>Total: ${p.x}</b><br/>Sent By: ${p.author}<br/>Negotiation Status: ${p.status}`
                        }
                    },
                    plotOptions: {
                        series: {
                            cursor: 'pointer',
                            events: {
                                click: function(event) { 
                                    if( popup === true )
                                        self.setGroupModal(event.point.pair_index)
                                }
                            }
                        }
                    },
                    series: [{
                        name: series.length === 1 ? series[0].name : 'Offers',
                        data: series.length === 1 ? series[0].data : [],
                        // data: [
                        //     [10,5],[11,5],[12,5],[13,5],
                        //     [12,6],[13,6],[14,6],[15,6],
                        //     [14,7],[15,7]
                        // ],
                        zIndex: 100
                    }, {
                        name: 'Polygons',
                        type: 'polygon',
                        // data: [[0, 0],[9, 0],[9, 3],[0, 3]],
                        data: [{id:'1',x:0,y:0}, {id:'2',x:0,y:4}, {id:'3',x:9,y:4}, {id:'4',x:9,y:0}],
                        color: 'rgba(255,0,0,.5)',
                        enableMouseTracking: false
                    }, {
                        name: 'Polygons',
                        type: 'polygon',
                        data: [
                            // [0, 3],[9, 3],[9, 6],[0, 6],[12, 6],[12, 7],[13, 7],[13, 8],[15, 8],[15, 11],[0, 11],
                            {id:'c1',x:0,y:4},{id:'c2',x:0,y:11},{id:'c3',x:16,y:11},{id:'c4',x:16,y:8},
                            // null,
                            // {id:'5',x:0,y:7},{id:'6',x:0,y:8},
                            {id:'c7',x:13,y:8},{id:'c8',x:13,y:7},
                            // null,
                            // {id:'9',x:0,y:3},{id:'10',x:0,y:7},
                            {id:'c11',x:11,y:7},{id:'c12',x:11,y:6},
                            // null
                            {id:'c13',x:9,y:6},{id:'c14',x:9,y:4}
                        ],
                        color: 'rgba(183, 180, 139, .5)',
                        enableMouseTracking: false
                    }, {
                        name: 'Polygons',
                        type: 'polygon',
                        data: [
                            {id:'b1',x:9,y:0},{id:'b2',x:9,y:4},{id:'b3',x:14,y:4},{id:'b4',x:14,y:5},{id:'b5',x:16,y:5},
                            {id:'b6',x:16,y:11},{id:'b7',x:20,y:11},{id:'b8',x:20,y:4},{id:'b9',x:20,y:0}
                        ],
                        color: 'rgba(220, 218, 198, .5)',
                        enableMouseTracking: false
                    }]
                }}
            />

            <Modal isOpen={ this.state.group_modal != null ? true : false } 
                centered={true} 
                size="xxl"
                toggle={ () => this.setState({ group_modal: null }) }>
                <ModalBody className="text-center">
                    <FontAwesomeIcon 
                        icon={ faTimesCircle } 
                        size="2x"
                        className="icon bg-white text-danger"
                        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', borderRadius: '50%' }}
                        onClick={() => this.setState({ group_modal: null })} />
                    { this.state.group_modal && <GroupDebrief group={ this.state.group_modal } /> }
                </ModalBody>
            </Modal>

        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(HistoryChart)