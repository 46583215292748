import React from 'react';
import { connect } from 'react-redux';

// Components
import BuilderStrumr from './BuilderStrumr';
import Message from './Message';

// The Header creates links that can be used to navigate
// between routes.
class ChatHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // componentDidUpdate(prevProps) {
  //     if( prevProps.offer && window.innerWidth > 1000 )
  //         this.chatBottom.scrollIntoView({ behavior: 'smooth', block: 'start' });
  // }

  render() {
    const self = this;
    const { group, offer } = this.props;

    const rawMessages = group
      ? group.chat.filter((c, ci) => {
          if (ci > 0) return c.created_at !== group.chat[ci - 1].created_at;
          else return true;
        })
      : [];
    let rawOffers = group ? group.items : [];
    rawOffers = rawOffers.map((o, oi) => ({
      ...o,
      acceptable: o.order === rawOffers[rawOffers.length - 1].order,
    }));

    // Mix & sort chats and offers
    let timeline = [...rawMessages, ...rawOffers];
    timeline.sort((a, b) => {
      const a_created_at = new Date(a.created_at).getTime();
      const b_created_at = new Date(b.created_at).getTime();
      // console.log(a.created_at, b.created_at)
      return a_created_at > b_created_at
        ? 1
        : b_created_at > a_created_at
        ? -1
        : 0;
    });

    let player = { ...group.players[0], counterpart: group.players[1] };

    let offersCount = 0;
    const timelineEls = timeline.map((o, oi) => {
      if (o.msg) {
        return (
          <Message
            key={'msg-' + oi}
            player={player}
            author={o.author}
            msg={o.msg}
          />
        );
      } else {
        offersCount++;
        const highlighted = offer && o._id === offer._id ? true : false;
        return (
          <div
            key={'off-' + oi}
            className='mb-3'
            style={{ position: 'relative' }}>
            {highlighted && (
              <div
                ref={(el) => {
                  self.chatBottom = el;
                }}></div>
            )}
            <BuilderStrumr
              highlighted={highlighted}
              player={player}
              key={'ob-' + oi}
              id={o._id}
              author={o.author}
              authorRole={o.author_role}
              band={o.band}
              finish={o.finish}
              status={o.status}
              editable={false}
              acceptable={o.acceptable}
              index={offersCount}
              onAccept={() => this.onAccept(o)}
              onLikeToggle={this.likeToggle}
            />
          </div>
        );
      }
    });

    return <div>{timelineEls}</div>;
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

export default connect(mapStateToProps)(ChatHistory);
