import React from 'react'
import { connect } from 'react-redux'

// Actions
// import { gameActions } from '../../actions';

import Header from '../../components/Header'
import { DebriefMenu } from './debrief_menu'

import InterestsImg from '../../assets/interests_infographic.png'

class DebriefInterests extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const { game } = this.props

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                How Interests Fuel Outcomes
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3 text-center bg-white">
                <img src={ InterestsImg } className="img-fluid" style={{ maxHeight: '70vh' }} alt="Interests" />
            </div>


        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefInterests)