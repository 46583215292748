import React from 'react';
import { connect } from 'react-redux';
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'

import { roleNames } from '../constants';

import DancefloorChartStrumr from './DancefloorChartStrumr';
import Summary from './Summary';
// import Bargaining from './Bargaining';
import ChatHistory from './ChatHistory';

// The Header creates links that can be used to navigate
// between routes.
class StudentDebrief extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 1,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let data = {};
    data[n] = v;
    this.setState(data);
  }

  totalPoints(rindex, finish, band) {
    // Points calculation
    let points_gained = 0;
    let points_lost = 0;
    // STRUMR
    if (rindex === 0) {
      points_gained = finish + band > 4 ? (finish + band - 4) * 10 : 0;
      points_lost = band > 5 ? (band - 5) * 3 : 0;
    }
    // LENNIGANS
    if (rindex === 1) {
      points_gained = band * 10;
      points_lost = finish + band > 8 ? (finish + band - 8) * 2 : 0;
    }
    // Total points for this role
    const total = points_gained - points_lost;
    return total;
  }

  render() {
    const { order } = this.state;
    const { group } = this.props;
    const maxOffers = group.items.length;
    const offer = group.items.find((i) => i.status === 'accepted');
    const selectedOffer = group.items[order - 1];

    const player0 = group.players.find((p) => p.rindex === 0);
    const player1 = group.players.find((p) => p.rindex === 1);

    let dyads = [group];

    const dances = selectedOffer
      ? dyads.map((dyad, di) => {
          const dance = [selectedOffer].map((i, ii) => {
            return {
              x: i.band + i.finish,
              y: i.band,
              status:
                dyad.status === 'nodeal'
                  ? ' No Deal'
                  : dyad.status === 'deal'
                  ? 'Deal'
                  : 'In Progress',
              author: roleNames[i.author_role],
              points: this.totalPoints(i.author_role, i.finish, i.band),
              marker: {
                enabled: true,
                radius: 12,
                fillColor:
                  i.author_role === 0
                    ? 'rgb(97, 46, 160,.7)'
                    : 'rgba(53, 119, 242,.7)',
              },
            };
          });

          return {
            name: `${dyad.players[0].email} vs ${dyad.players[1].email}`,
            color: 'rgba(53, 119, 242)',
            data: dance,
            showInLegend: false,
            zIndex: 99,
          };
        })
      : [];

    return (
      <div>
        <div className='row'>
          <div className='col-12 text-center mt-3'>
            <p className='lead mb-0'>
              Pair Feedback: {player0.email} &amp; {player1.email}
              {offer && (
                <small>
                  <br />
                  Scroll the bar to review offers
                </small>
              )}
            </p>
          </div>

          <div
            className='col-12 col-md-10 offset-md-1 mb-4'
            style={{ marginTop: '-10px' }}>
            {!offer && (
              <h5 className='mb-0 mt-2 text-center text-danger'>
                No offer was accepted.
              </h5>
            )}
            <h5 className='mb-0 mt-0 text-left'>
              Offer #{this.state.order} of {group.items.length}
            </h5>
            <input
              name='order'
              type='range'
              min='1'
              max={maxOffers}
              value={this.state.order}
              onChange={this.handleChange}
            />
          </div>

          <div className='col-12 col-md-8 mb-4'>
            <div className='card'>
              <div className='card-header'>Offer Evolution Over Time</div>
              <div
                className='card-body'
                style={{ height: '60vh', overflow: 'auto' }}>
                <DancefloorChartStrumr dances={dances} squares={true} />
              </div>
            </div>
          </div>

          <div className='col-12 col-md-4 mb-4'>
            <div className='card'>
              <div className='card-header text-left'>
                Chat History
                <small className='float-right'>
                  <i>STRUMR View</i>
                </small>
              </div>
              <div
                className='card-body'
                style={{ height: '60vh', overflow: 'auto' }}>
                <ChatHistory group={group} offer={selectedOffer} />
              </div>
            </div>
          </div>

          <div className='col-12 mb-3'>
            <div className='row'>
              <div className='col-6'>
                <div className='card'>
                  <div className='card-header'>Offer Details</div>
                  <div className='card-body'>
                    <Summary group={group} />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='card'>
                  <div className='card-header'>Additional Terms</div>
                  <div className='card-body'>
                    <p>
                      <b>
                        {roleNames[0]} <small>({player0.email})</small>
                      </b>
                      :{' '}
                      {player0.terms.length > 0 ? (
                        player0.terms
                      ) : (
                        <i>No additional terms specified.</i>
                      )}
                    </p>
                    <p>
                      <b>
                        {roleNames[1]} <small>({player1.email})</small>
                      </b>
                      :{' '}
                      {player1.terms.length > 0 ? (
                        player1.terms
                      ) : (
                        <i>No additional terms specified.</i>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(StudentDebrief);
