import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

class Debrief extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.item || null
        }

        // Bindings

    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
        }
    }

    componentWillReceiveProps(newProps){ 
        this.setState({
            item: newProps.item
        });
    }

    render(){

        const { game } = this.props;

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                Debrief
                <small class="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>
            
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

const connectedGamesPage = connect(mapStateToProps)(Debrief);
export { connectedGamesPage as GameDebrief };
