import React from 'react'
// import numeral from 'numeral';
import { roleNames } from '../constants'


// The Header creates links that can be used to navigate
// between routes.
class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render(){

        const { players } = this.props.group;
        const player0 = players.find( p => p.rindex === 1 )
        const player1 = players.find( p => p.rindex === 0 )

        return <div>
            <div className="row">
                <div className="col-6 text-center">
                    <p className="mb-1">{roleNames[0]}<br/>Satisfaction</p>
                    <h4 className="mb-0">{ player0.satisfaction || 'Pending' }</h4>
                </div><div className="col-6 text-center">
                    <p className="mb-1">{roleNames[1]}<br/>Satisfaction</p>
                    <h4 className="mb-0">{ player1.satisfaction || 'Pending' }</h4>
                </div>
            </div>
        </div>
    }

}

export default Summary