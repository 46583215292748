import React from 'react';

import AddPlayerForm from './add_player_form';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AddPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forms: [{}],
    };

    // Bindings
    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  add() {
    const forms = [...this.state.forms];
    forms.push({});
    this.setState({ forms });
  }

  remove() {
    const forms = [...this.state.forms];
    forms.splice(-1);
    this.setState({ forms });
  }

  handleFormChange(index, form) {
    const forms = [...this.state.forms];
    forms[index] = form;
    this.setState({ forms }, () => {
      this.props.onChange && this.props.onChange(forms);
    });
  }

  render() {
    const { roles } = this.props;

    const forms = this.state.forms.map((f, fi) => {
      return (
        <div className='text-left'>
          {/* <label>
            <small>
              {roles && roles.length > 0
                ? roles[fi % roles.length]
                : 'Role #' + (fi + 1)}
            </small>
          </label> */}
          <AddPlayerForm
            key={`form-${fi}`}
            onChange={(form) => this.handleFormChange(fi, form)}
          />
        </div>
      );
    });

    return (
      <div>
        {forms}

        {/* <button className="btn btn-md btn-light float-left" onClick={ this.add }>
                <FontAwesomeIcon className="text-success" icon="plus-circle" size="1x" style={{ cursor: 'pointer' }} />
            </button> */}
      </div>
    );
  }
}

export default AddPlayer;
