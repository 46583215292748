import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { Modal, ModalBody } from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';

// Actions
import { gameActions } from '../actions';

const RoundSelect = (props) => {
  const { game, dispatch } = props;

  // Hooks
  const [selectedRound, setSelectedRound] = useState('');
  const [snaps, setSnaps] = useState([]);
  const [modal, setModal] = useState(false);
  const [highlight, setHiglight] = useState('');

  // On mount
  useEffect(() => {
    if (game?.data?._id) fetchSnapshots(game.data._id);
  }, []);

  // On dropdown change: highlight
  useEffect(() => {
    setHiglight('highlight');
    setTimeout(() => setHiglight(''), 500);
  }, [selectedRound]);

  // Function: Create new round
  const newRound = () => {
    fetch(
      process.env.REACT_APP_API_URL + '/games/' + game.data._id + '/snapshot',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data._id) {
          setModal(false);
          dispatch(gameActions.fetchGames(game.data._id));
          fetchSnapshots(game.data._id);
        }
      });
  };

  // Function: Retrieve game snapshots
  const fetchSnapshots = (game_id) => {
    fetch(process.env.REACT_APP_API_URL + '/games/' + game_id + '/snapshot', {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          setSnaps(data.reverse());
          if (game?.round) return setRound({ target: { value: game.round } });
          else setSelectedRound('');
        }
      });
  };

  // Function: Set an active snapshot
  const setRound = (e) => {
    const r = e.target.value ? e.target.value : null;
    if (r === null) {
      setSelectedRound('');
      dispatch(gameActions.setRound(r));
      return dispatch(gameActions.fetchGames(game.data._id));
    }

    if (r === 'new') {
      return setModal(true);
    }

    setSelectedRound(r);
    dispatch(gameActions.setRound(r));

    fetch(
      process.env.REACT_APP_API_URL +
        '/games/' +
        game.data._id +
        '/snapshot/' +
        r,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => dispatch(gameActions.setData(data.data)));
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <div>
      <select
        style={{ fontSize: '14px', color: '#333', background: '#F6F6F6' }}
        className={`form-control form-control-sm border-0 ${highlight}`}
        value={selectedRound}
        onChange={setRound}>
        <option key='round-null' value=''>
          Round #{snaps.length + 1} &nbsp; (Latest)
        </option>
        {snaps.map((s, si) => {
          return (
            <option key={`round-${si + 1}`} value={s._id}>
              Round #{snaps.length - si}
            </option>
          );
        })}
        <option key='round-new' value='new'>
          CREATE NEW ROUND
        </option>
      </select>

      <Modal isOpen={modal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal}>
          Confirm New Round Creation
        </ModalHeader>
        <ModalBody className='text-center'>
          <div className='m-4'>
            <p>Please confirm that you want create a NEW ROUND.</p>
            <p>
              Be aware that this will create a snapshot of the current round
              that will you can review in the debrief. These snapshots cannot be
              modified so be sure that all learners are finished with the
              current round before creating a new one.
            </p>
            <p>
              Once a new round is created, learners will be able to replay the
              sim and their subsequent decisions will be captured in the new,
              “latest” round.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className='text-right'>
          <button
            className='btn btn-outline-secondary'
            onClick={() => setModal(false)}>
            Cancel
          </button>
          <button className='btn btn-outline-primary' onClick={newRound}>
            Confirm
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(RoundSelect);
