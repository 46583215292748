import React from 'react'
import { connect } from 'react-redux'

// Actions
// import { gameActions } from '../../actions';

import { roleNames } from '../../constants'

import Header from '../../components/Header'
import { DebriefMenu } from './debrief_menu'
import PointsTable from '../../components/PointsTable'

class DebriefZOPA extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            role0: false,
            role1: false,
            zopa: false
        }
    }

    render() {

        const { role0, role1, zopa } = this.state
        const { game } = this.props

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                ZOPA
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="mt-3 text-center bg-white position-relative">
                
                <div style={{ minHeight: '477px' }}>
                    { !role0 && !role1 && !zopa && <p className="pt-5 lead">
                        Click role button to display points table.<br/>
                        Click <b className="text-primary">ZOPA</b> to reveal zone of potential agreement.
                    </p> }
                    { (role0 || zopa) && <div style={zopa ? { position: 'relative', top: 0, left: 0, width: '100%', opacity: .8 } : {}}>
                        <PointsTable role={0} rows={15} cols={15} hide={zopa ? true : false} /> 
                    </div> }
                    { (role1 || zopa) && <div style={zopa ? { position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 10, opacity: .8 } : {}}>
                        <PointsTable role={1} rows={15} cols={15} hide={zopa ? true : false} /> 
                    </div> }
                </div>

                <div className="mt-3 pb-3">
                    <button 
                        style={{ width: '150px' }}
                        className={role0?'btn btn-primary mr-2':'btn btn-outline-primary mr-2'}
                        onClick={ e => this.setState({ role0: !role0, role1: false, zopa: false })}>{roleNames[0]}</button>
                    <button 
                        style={{ width: '150px' }}
                        className={role1?'btn btn-primary ml-2':'btn btn-outline-primary ml-2'}
                        onClick={ e => this.setState({ role1: !role1, role0: false, zopa: false })}>{roleNames[1]}</button>
                    <button 
                        style={{ width: '150px' }}
                        className={zopa?'btn btn-primary ml-2':'btn btn-outline-primary ml-2'}
                        onClick={ e => this.setState({ zopa: !zopa, role0: false, role1: false })}>ZOPA</button>
                </div>

            </div>


        </div>
    }

}

const mapStateToProps = state => {
    const { game } = state
    return {
        game
    }
}

export default connect(mapStateToProps)(DebriefZOPA)