import { gamesConstants } from '../constants';

export function games(state = {}, action) {
  switch (action.type) {
    case gamesConstants.SET_DATA:
      return {
        data: action.data,
      };
    case gamesConstants.SET_GAMES:
      return [...action.data];
    case gamesConstants.REMOVE_ITEM:
      let newState = [...state];
      const removeIndex = newState.findIndex((g) => g._id === action.data._id);
      if (removeIndex > -1) {
        // newState.splice(removeIndex, 1);
        newState[removeIndex] = action.data;
      }
      return newState;
    default:
      return state;
  }
}
