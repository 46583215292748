import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class AddPlayerForm extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            name: '',
            lastname: '',
            email: ''
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange(e){
        const n = e.target.name;
        const v = e.target.value;
        let state = {...this.state};
        state[n] = v;
        this.setState(state, () => {
            this.props.onChange && this.props.onChange(state)
        });
    }

    render(){

        return <div className="form-row mb-3">
                <div className="col">
                    <input name="name" className="form-control form-control-lg" value={ this.state.name } placeholder="Name" onChange={ this.handleChange } />
                </div>
                <div className="col">
                    <input name="lastname" className="form-control form-control-lg" value={ this.state.lastname } placeholder="Surname" onChange={ this.handleChange } />
                </div>
                <div className="col">
                    <input name="email" className="form-control form-control-lg" value={ this.state.email } placeholder="Email" onChange={ this.handleChange } />
                </div>
                {/* <div className="col-1">
                    <button className="btn btn-light btn-md">
                        <FontAwesomeIcon className="text-danger" icon="trash-alt" size="1x" style={{ cursor: 'pointer' }} />
                    </button>
                </div> */}
            </div>
    }

}

export default AddPlayerForm