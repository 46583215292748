import { gameConstants } from '../constants';

export function game(state = { data: null, round: null }, action) {
  switch (action.type) {
    case gameConstants.SET_DATA:
      const d = action.data;
      if (d === null) return { ...state, data: null };
      else
        return {
          ...state,
          data: { ...state.data, ...d },
        };
    case gameConstants.SET_ROUND:
      const round = action.data;
      return {
        ...state,
        round,
      };
    case gameConstants.SET_GAMES:
      return {
        ...state,
        games: action.data,
      };
    case gameConstants.ADD_PLAYERS:
      const newpls = action.data;
      const totalPlayers = [...state.data.players, ...newpls];
      return {
        ...state,
        data: { ...state.data, ...{ players: totalPlayers } },
      };
    default:
      return state;
  }
}
