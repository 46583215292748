import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  {
    games: [],
    game: {
      data: null,
      round: null,
    },
    player: {
      results: [],
    },
    modal: {
      visibility: false,
    },
  },
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);
