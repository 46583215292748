import React from 'react'

class PointsTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        // Bindings 
    }

    // componentDidMount() {}

    totalPoints(rindex, total, band){
        // Points calculation
        let points_gained = 0
        let points_lost = 0
        // STRUMR
        if( rindex === 0 ) {
            points_gained = (total) > 4 ? ((total) - 4) * 10 : 0
            points_lost = band > 5 ? (band - 5) * 3 : 0
        }
        // LENNIGANS
        if( rindex === 1 ){
            points_gained = band * 10
            points_lost = (total) > 8 ? ((total) - 8) * 2 : 0
        }
        // Total points for this role
        return points_gained - points_lost;
    }

    render() {

        const { role, rows, cols, hide } = this.props

        const rowsArray = []
        let headers = []
        headers.push(<td key={`label`} 
            rowSpan={rows+1} 
            className="font-weight-bold" 
            style={{ verticalAlign: 'middle', width: '30px', position: 'relative' }}>
            <span style={{
                transform: 'rotate(-90deg) translateX(-50%)',
                display: 'block',
                transformOrigin: '0 0',
                whiteSpace: 'nowrap',
                position: 'absolute',
                height: '100%'
            }}>Vendor stands in band area</span>
        </td>)
        headers = [...headers, Array(Number(cols)+1).fill(Number(cols)+1).map((c,ci) => <td key={`header-0-${ci}`} className="font-weight-bold">{ci}</td>)]
        rowsArray.push(<tr key={`row-0`}>{headers}</tr>)
        for(let r = 1; r <= rows; r++){
            const cells = []
            cells.push(<td key={`cell-0-0`} style={{ width: '30px' }} className="font-weight-bold">{r}</td>)
            for(let c = 1; c <= cols; c++){
                const totalpoints = this.totalPoints(role, c, r)
                let cellClass = ''
                // let cellClass = r > c ? '#EEE' : ''

                cellClass = role === 0 && totalpoints < 60 ? '#EEE' : cellClass
                cellClass = role === 0 && r > c/2 ? '#EEE' : cellClass

                cellClass = role === 1 && totalpoints < 40 ? '#EEE' : cellClass
                // cellClass = role === 1 && c > 15 ? '#EEE' : cellClass

                cells.push(<td key={`cell-${r}-${c}`} style={{ background: cellClass }}>
                    { totalpoints < 0 || r > c || hide ? '' : totalpoints}
                </td>)
            }
            rowsArray.push(<tr key={`row-${r}`}>{cells}</tr>)
        }
        
        return <div className="text-center">
            <table className="table table-bordered table-sm mb-0" style={{ fontSize: '0.8rem' }}>
                <tbody>
                    <tr>
                        <td colSpan={cols+2} className="font-weight-bold">Total number of vendor stands</td>
                    </tr>
                    {rowsArray}
                </tbody>
            </table>
        </div>
    }

}
export default PointsTable