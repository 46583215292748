//import { modalConstants } from '../constants';

export const modalActions = {
    open,
    close
};

function open( options ) {
    return { type: "OPEN_CONFIRM_MODAL", options }
}

function close() {
    return { type: "CLOSE_CONFIRM_MODAL" };
}