import React from 'react';
import { render } from 'react-dom';

// import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { store } from './helpers';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
// import registerServiceWorker from './registerServiceWorker';

render((
    <Provider store={ store }>
      <App />
    </Provider>
  ), document.getElementById('root'));
  
//registerServiceWorker();