import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

// import { playerActions, gameActions } from '../../actions';
// import { setupSocket, enterGame } from '../../helpers';

import posed, { PoseGroup } from 'react-pose';

import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
import appLogo from '../assets/app_icon.png';

class HomePage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: null
        }
        // Bindings
        this.apiGetClientData = this.apiGetClientData.bind(this)
    }
    
    componentDidMount() {
        this.apiGetClientData()
        console.log(this.props.match)
    }

    apiGetClientData(){
        fetch(process.env.REACT_APP_API_URL + '/auth/oauth_provider')
            .then( response => response.json() )
            .then( data => this.setState({ data }))
    }

    render() {

        const { user } = this.props;
        const { data } = this.state;

        const authLink = data ? data.hub_url + '/oauth/authorize?client_id=' + data.client_id + '&response_type=code&redirect_uri=' + data.redirect_uri + '&scope=' + data.scope : '';

        return (
            <div className="row bgcolors">
                <div className="panel-sidebar col-3 text-center d-none d-md-block" style={{ height: '100vh' }}>
                    <p className="v-center">
                        <img src={scLogoWide} className="mb-3" style={{ maxWidth: '50%' }} alt="SimCase" />
                        <br />
                        <small>
                            <a href="http://simcase.io" target="_blank">Learn More</a>
                        </small>
                    </p>
                </div>
                <div className="col-12 col-sm-10 offset-sm-1 col-md-5 offset-md-5 text-center" style={{ height: '100vh' }}>
                    { data && <div className="v-center">
                        <div className="card">
                            <div className="card-body pt-5 pb-5">
                                <h3 className="mt-0 mb-0 title-font font-weight-light">
                                    Welcome to <span className="title-color">{ data.app_name }</span>
                                </h3>
                                <img src={appLogo} style={{ maxHeight: '180px' }} className="img-fluid mt-3 mb-3" alt="" />
                                <br/>
                                <a href={authLink} className="btn btn-outline-primary btn-lg">Log In</a>
                                <p className="mt-5 mb-0 d-md-none text-center">
                                    <img src={scLogo} style={{ width: '25px' }} alt="" />
                                    <br/>
                                    <small className="text-secondary">Developed by Simcase</small>
                                </p>
                            </div>
                        </div>
                    </div> }
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };