import React from 'react'

class CSVDrop extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            status: 'init'
        }
        // Bindings 
        this.handleDragEnter = this.handleDragEnter.bind(this)
        this.handleDragLeave = this.handleDragLeave.bind(this)
        this.handleDragOver  = this.handleDragOver.bind(this)
        this.handleDrop      = this.handleDrop.bind(this)
    }

    // componentDidMount() {}

    handleDragEnter(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({ status: 'drag' })
    }

    handleDragLeave(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({ status: 'init' })
    }

    // This one is used for prevent default
    handleDragOver(e) {
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    handleDrop(e){
        e.preventDefault()
        e.stopPropagation()
        const file = e.dataTransfer.files[0]
        const reader = new FileReader();
        reader.onload = event => {
            const content = event.target.result.replace(/;/g, ',');
            this.setState({ status: 'init' })
            this.props.onComplete && this.props.onComplete(content)
        };
        reader.readAsText(file);
    }

    render() {

        const { status } = this.state
        const bodyStyle = {
            init: {},
            drag: { opacity: .2 }
        }
        const overlayStyle = {
            init: { display: 'none' },
            drag: { 
                display: 'block', position: 'absolute', top: '0px', left: '0px', 
                width: '100%', height: '100%', 
                fontSize: '1.5rem', textAlign: 'center', color: '#444', paddingTop: '100px',
                background: 'rgba(255, 255, 255, 0.8)', zIndex: '-1' }
        }

        return <div className={`status-${status}`} style={{ position: 'relative' }}
            onDragEnter={ this.handleDragEnter } 
            onDragLeave={ this.handleDragLeave } 
            onDrop={ this.handleDrop }
            onDragOver={ this.handleDragOver }>
            <div style={bodyStyle[status]}>{ this.props.children }</div>
            <div style={overlayStyle[status]}>Drop your CSV file</div>
        </div>
        
    }

}
export default CSVDrop