import { playerConstants } from '../constants';

export function player(state = {}, action) {
  switch (action.type) {
    case playerConstants.SET_DATA:
      return action.data;
    case playerConstants.PUSH_RESULT:
      return {
        ...state, 
        results: [...state.results, action.data]
      }
    default:
      return state
  }
}