import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { roleNames } from '../constants'
// import NumbericInput from '../../components/NumericInput'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

class BuilderStrumr extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
            _id: typeof this.props.id != 'undefined' ? this.props.id : null,
            author: this.props.author,
            editable: typeof this.props.editable != 'undefined' ? this.props.editable : true,
            acceptable: typeof this.props.acceptable != 'undefined' ? this.props.acceptable : false, 
            status: this.props.status || '',

            finish: this.props.finish ? this.props.finish : 0,
            band: this.props.band ? this.props.band : 0,

            accept_modal: false
        }

        // Bindings
        this.handleChange       = this.handleChange.bind(this);
        this.handleInputChange  = this.handleInputChange.bind(this);
        this.onSubmit           = this.onSubmit.bind(this);
        this.onAccept           = this.onAccept.bind(this);
        this.onCancel           = this.onCancel.bind(this);
        this.toggleModal        = this.toggleModal.bind(this);
        this.clearBuilderStrumr = this.clearBuilderStrumr.bind(this);

    }

    componentDidUpdate(prevProps) {

        let edit = false;
        let newState = {};

        if (this.props.editable !== prevProps.editable){
            newState['editable'] = this.props.editable;
            edit = true;
        }

        if (this.props.acceptable !== prevProps.acceptable){
            newState['acceptable'] = this.props.acceptable;
            edit = true;
        }

        if (this.props.status !== prevProps.status){
            newState['status'] = this.props.status;
            edit = true;
        }

        if (this.props.amount !== prevProps.amount){
            newState['amount'] = this.props.amount;
            edit = true;
        }

        if( edit )
            this.setState(newState)

    }

    handleChange(e){
        const n = e.target.name;
        const v = e.target.value;
        this.setState({ [n]: Number(v) }, () => {
            const { finish, band } = this.state;
            if(this.props.onChange)
                this.props.onChange({ finish, band });
        })
    }

    handleInputChange(value){
        this.setState({ amount: value }, () => {
            if(this.props.onChange)
                this.props.onChange(parseInt(this.state.amount));
        })
    }

    onSubmit(){
        const { amount } = this.state
        if( this.props.onSubmit )
            this.props.onSubmit({ amount });
    }

    onAccept(){
        this.toggleModal(false)
        if( this.props.onAccept )
            this.props.onAccept();
    }

    onCancel(){
        if( this.props.onCancel )
            this.props.onCancel();
    }

    toggleModal( mode ){
        this.setState({ accept_modal: mode });
    }

    clearBuilderStrumr(){
        this.setState({ amount: '' })
    }

    totalPoints(rindex, finish, band){
        // Points calculation
        let points_gained = 0
        let points_lost = 0
        // STRUMR
        if( rindex === 0 ) {
            points_gained = (finish + band) > 4 ? ((finish + band) - 4) * 10 : 0
            points_lost = band > 5 ? (band - 5) * 3 : 0
        }
        // LENNIGANS
        if( rindex === 1 ){
            points_gained = band * 10
            points_lost = (finish + band) > 8 ? ((finish + band) - 8) * 2 : 0
        }
        // Total points for this role
        const total = points_gained - points_lost;
        return total;
    }

    render() {

        const { player, index } = this.props;
        const { finish, band } = this.state;

        const mine = player._id === this.state.author ? true : false;
        const sentBy = mine ? roleNames[player.rindex] : roleNames[player.counterpart.rindex];

        // Total points for this role
        const total = this.totalPoints(player.rindex, finish, band)

        let roleBox = mine ? 'bg-kellogg' : 'bg-white';

        const viewMode = <div className={`card offer-box ${roleBox} p-2 position-relative`}>
            {/* <div className="col-12 col-md-4 text-kellogg text-center text-md-left">
                <small>
                    Offer #{ this.props.index } 
                    { this.state.editable && <b 
                        style={{ fontSize: '10px', padding: '0 3px' }}
                        className="d-inline-block ml-2 btn btn-warning" 
                        onClick={ this.clearBuilderStrumr }>Clear</b> }
                </small>
            </div> */}
            {/* <div className="position-absolute text-kellogg" style={{ fontSize: '.65rem', top: '4px', right: '8px', zIndex: 99 }}>
                <a href="#" className="text-kellogg">Points Table</a>
            </div> */}
            <div className="row text-center">

                <div className="col-3 pr-0">
                    <div className="lead mb-0">
                        <div className="text-kellogg pt-3" style={{ fontSize: '1.3rem' }}>
                            <small className="font-bold">Total</small><br/>
                            { finish + band }
                        </div>
                    </div>
                </div>
                <div className="col-6 text-kellogg">

                    <div className="pb-2">Offer #{ index }</div>
                    <small className="d-block" style={{ fontSize: '.7rem' }}><b>Points</b>: { total }</small> 
                    <small className="d-block" style={{ fontSize: '.7rem' }}><b>Spend</b>: { numeral((finish + band) * 300).format('$0,0') }</small>
                    {/* <small className="d-block" style={{ fontSize: '.7rem' }}><b>Booths</b>: { finish + band }</small> */}

                    { !mine && !this.state.editable && this.state.status === 'init' && this.state.acceptable && <div className="mt-3 mb-1 text-center">
                        <span className="btn btn-outline-kellogg mb-1" onClick={ () => this.toggleModal(true) }>Accept Offer</span>
                    </div> }
                    
                    { mine && !this.state.editable && this.state.acceptable && <div className="mt-1 mb-1 text-center">
                        <span className="font-weight-bold">Sent</span>
                    </div> }

                    { !this.state.editable && !this.state.acceptable && <div className="mt-1 mb-1 text-center">
                        <span className="font-weight-bold text-danger">Rejected</span>
                    </div> }

                </div>
                <div className="col-3 pl-0">
                    <div className="lead mb-0">
                        <div className="text-kellogg pt-3" style={{ fontSize: '1.3rem' }}>
                            <small className="font-bold">Band</small><br/>
                            { band }
                        </div>
                    </div>
                </div>

                <div className="col-12 text-kellogg text-center">
                    <small className="float-rightx">
                        Sent By: { sentBy }
                        { this.state.editable && 
                            <FontAwesomeIcon 
                                icon={ faTimesCircle } 
                                size="2x"
                                className="icon bg-white text-danger"
                                style={{
                                    position: 'absolute',
                                    top: '-12px',
                                    right: '-7px',
                                    cursor: 'pointer',
                                    borderRadius: '50%'
                                }}
                                onClick={ this.onCancel } />
                        }
                    </small>
                </div>
            </div>
        </div>

        // Dropdown Options
        const dropdownOptions = []
        for( let i = 0; i <=20; i++ ){
            dropdownOptions.push(<option key={`op-${i}`}>{i}</option>)
        }

        const enterMode = <div className="enter-mode">
                    {/* <div className="clearfix">

                        <small className="float-left">
                            Offer #{ this.props.index } 
                            { this.state.editable && <b 
                                style={{ fontSize: '10px', padding: '0 3px' }}
                                className="d-inline-block ml-2 btn btn-warning" 
                                onClick={ this.clearBuilderStrumr }>Clear</b> }
                        </small>

                        <small className="float-right">
                            <FontAwesomeIcon 
                                icon={ faTimesCircle } 
                                size="2x"
                                className="icon bg-white text-danger"
                                style={{
                                    position: 'absolute',
                                    top: '-12px',
                                    right: '-7px',
                                    cursor: 'pointer',
                                    borderRadius: '50%'
                                }}
                                onClick={ this.onCancel } />
                        </small>
                        
                    </div> */}
                    <div>
                        <div className="row text-center">
                            <div className="col-6 text-kellogg text-center" style={{ lineHeight: '1rem' }}>
                                <small style={{ fontSize: '.7rem' }}><b>Points</b>: { total }</small> 
                                <br/>
                                <small style={{ fontSize: '.7rem' }}><b>Spend</b>: { numeral((finish + band) * 300).format('$0,0') }</small>
                                <br/>
                                <small style={{ fontSize: '.7rem' }}><b>Booths</b>: { finish + band }</small>
                            </div>
                            <div className="col-3 pl-0">
                                <div className="btn btn-block btn-outline-kellogg position-relative" style={{ padding: '.25rem', lineHeight: '1rem' }}>
                                    <small className="d-block text-center" style={{ position: 'absolute', width: '100%', left: 0, fontSize: '.65rem' }}>Finish</small>
                                    <select name="finish" value={finish} className="form-control position-relative builder-select" onChange={ this.handleChange }>
                                        { dropdownOptions }
                                    </select>
                                </div>
                            </div>
                            <div className="col-3 pl-0">
                                <div className="btn btn-block btn-outline-kellogg position-relative" style={{ padding: '.25rem', lineHeight: '1rem' }}>
                                    <small className="d-block text-center" style={{ position: 'absolute', width: '100%', left: 0, fontSize: '.65rem' }}>Band</small>
                                    <select name="band" value={band} className="form-control position-relative builder-select" onChange={ this.handleChange }>
                                        { dropdownOptions }
                                    </select>
                                </div>
                            </div>

                            {/* <div className="mt-0">
                                <NumbericInput 
                                    autoFocus
                                    value={ this.state.amount }
                                    className="form-control form-control-lg text-center" 
                                    onChange={ this.handleInputChange } 
                                    placeholder="Enter your offer in $M USD"
                                    />
                            </div> */}

                        </div>
                    </div>
                </div>

        return (
            <div>

                { this.state.editable && enterMode }
                { !this.state.editable && viewMode }

                <Modal isOpen={ this.state.accept_modal } centered={true}>
                    <ModalBody>
                        Confirm that you want to accept this offer.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="outline-kellogg" onClick={ this.onAccept }>Accept</Button>
                        <Button color="secondary" onClick={() => this.toggleModal(false)}>Cancel</Button>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {
    // const { player } = state;
    return { };
}

export default connect(mapStateToProps)(BuilderStrumr);