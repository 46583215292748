import React from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsReact from 'highcharts-react-official';
// Group Debrief
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import GroupDebrief from './GroupDebrief';

// Enable accessibility
highchartsAccessibility(Highcharts);

class DancefloorChartStrumr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: 0,
      squares: this.props.squares || false,
      group_modal: null,
    };
    // Bindings
    this.setGroup = this.setGroup.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.setGroupModal = this.setGroupModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.squares !== this.props.squares) {
      this.setState({ squares: this.props.squares });
    }
  }

  setGroup(v) {
    const { dances } = this.props;
    let value = v;
    if (v < 0) value = dances.length;
    if (v > dances.length) value = 0;
    this.setState({ group: value });
  }

  handleGroupChange(e) {
    const value = e.target.value;
    if (value.length > 0) this.setState({ group: parseInt(value) });
  }

  setGroupModal(gindex) {
    const { game } = this.props;
    const group_modal = game.data ? game.data.groups[gindex] : null;
    this.setState({ group_modal });
  }

  render() {
    const self = this;
    const { group, squares } = this.state;
    const { dances, popup } = this.props;

    // const series = dances[group-1] ? [dances[group-1]] : dances
    const series =
      group > 0
        ? dances.map((d, di) => {
            d.visible = di === group - 1 ? true : false;
            return d;
          })
        : dances.map((d, di) => {
            d.visible = true;
            return d;
          });

    return (
      <div style={{ height: '100%' }}>
        {dances.length > 1 && (
          <div className='clearfix mb-3'>
            <div className='col-2 float-right'>
              <div className='input-group input-group-sm'>
                <div className='input-group-prepend'>
                  <button
                    class='btn btn-primary'
                    onClick={() => this.setGroup(group - 1)}>
                    Prev
                  </button>
                </div>
                <input
                  type='text'
                  className='form-control text-center'
                  value={group === 0 ? 'All' : group}
                  placeholder='#'
                />
                <div className='input-group-append'>
                  <button
                    class='btn btn-primary'
                    onClick={() => this.setGroup(group + 1)}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <HighchartsReact
          containerProps={{ style: { height: '400px' } }}
          highcharts={Highcharts}
          options={{
            title: { text: '' },
            chart: { type: 'scatter' },
            credits: { enabled: false },
            legend: { enabled: true },
            xAxis: {
              title: { text: 'Total # of Stands' },
              allowDecimals: false,
              min: 0,
              minRange: 20,
              // categories: ['Issue by Issue', 'Package Offer', 'MESOs', 'Finished Negotiation']
            },
            yAxis: {
              title: { text: '# of Stands in Band Area' },
              allowDecimals: false,
              min: 0,
              minRange: 18,
            },
            tooltip: {
              formatter: function () {
                const p = this.point;
                // return `<b>${this.series.name}</b><br/><b>Band: ${p.y}</b><br/><b>Total: ${p.x+p.y}</b><br/><b>Points: ${p.points}</b><br/>Sent By: ${p.author}<br/>Negotiation Status: ${p.status}`
                return `<b>${this.series.name}</b><br/><b>Band: ${
                  p.y
                }</b><br/><b>Finish: ${p.x - p.y}</b><br/><b>Total: ${
                  p.x
                }</b><br/>Sent By: ${p.author}<br/>Negotiation Status: ${
                  p.status
                }`;
              },
            },
            plotOptions: {
              series: {
                cursor: 'pointer',
                events: {
                  click: function (event) {
                    if (popup === true)
                      self.setGroupModal(event.point.pair_index);
                  },
                },
              },
            },
            series: [
              ...series,
              {
                name: 'Minimum',
                data: [
                  { x: 10, y: 5 },
                  { x: 11, y: 5 },
                ],
                marker: {
                  symbol: 'square',
                  fillColor: '#FFFFFF',
                  lineColor: '#EB0000',
                  lineWidth: 3,
                  radius: 7,
                },
                enableMouseTracking: false,
                showInLegend: squares,
                visible: squares,
                zIndex: 10,
              },
              {
                name: 'Good',
                data: [
                  { x: 12, y: 5 },
                  { x: 13, y: 5 },
                ],
                marker: {
                  symbol: 'square',
                  fillColor: '#FFFFFF',
                  lineColor: '#2A8900',
                  lineWidth: 3,
                  radius: 7,
                },
                enableMouseTracking: false,
                showInLegend: squares,
                visible: squares,
                zIndex: 10,
              },
              {
                name: 'Better',
                data: [
                  { x: 12, y: 6 },
                  { x: 13, y: 6 },
                  { x: 14, y: 6 },
                ],
                marker: {
                  symbol: 'square',
                  fillColor: '#FFFFFF',
                  lineColor: '#05819E',
                  lineWidth: 3,
                  radius: 7,
                },
                enableMouseTracking: false,
                showInLegend: squares,
                visible: squares,
                zIndex: 10,
              },
              {
                name: 'Best',
                data: [
                  { x: 14, y: 7 },
                  { x: 15, y: 7 },
                  { x: 15, y: 6 },
                ],
                marker: {
                  symbol: 'square',
                  fillColor: '#FFFFFF',
                  lineColor: '#0000DD',
                  lineWidth: 3,
                  radius: 7,
                },
                enableMouseTracking: false,
                showInLegend: squares,
                visible: squares,
                zIndex: 10,
              },
            ],
          }}
        />

        <Modal
          isOpen={this.state.group_modal != null ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group_modal: null })}>
          <ModalBody className='text-center'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => this.setState({ group_modal: null })}
            />
            {this.state.group_modal && (
              <GroupDebrief group={this.state.group_modal} />
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { game } = state;
  return {
    game,
  };
};

export default connect(mapStateToProps)(DancefloorChartStrumr);
