import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { roleNames } from '../../constants';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params.id) {
      dispatch(gameActions.fetchGames(match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  totalPoints(rindex, finish, band) {
    // Points calculation
    let points_gained = 0;
    let points_lost = 0;
    // STRUMR
    if (rindex === 0) {
      points_gained = finish + band > 4 ? (finish + band - 4) * 10 : 0;
      points_lost = band > 5 ? (band - 5) * 3 : 0;
    }
    // LENNIGANS
    if (rindex === 1) {
      points_gained = band * 10;
      points_lost = finish + band > 8 ? (finish + band - 8) * 2 : 0;
    }
    // Total points for this role
    const total = points_gained - points_lost;
    return total;
  }

  render() {
    const { game } = this.props;
    let groups = game.data.groups || [];
    groups = JSON.parse(JSON.stringify(groups));
    groups = groups.map((p, pi) => {
      const player0 = p.players.find((pl) => pl.rindex === 0);
      const player1 = p.players.find((pl) => pl.rindex === 1);

      const accepted = p.items.find((i) => i.status === 'accepted');

      const points0 = accepted
        ? this.totalPoints(0, accepted.finish, accepted.band)
        : 0;
      const points1 = accepted
        ? this.totalPoints(1, accepted.finish, accepted.band)
        : 0;

      // const totalSatisfaction = p.players.reduce( (acc, pl) => acc + pl.satisfaction, 0)

      p.group = {
        value: pi + 1,
        label: (
          <button
            className='btn btn-outline-primary'
            style={{ lineHeight: '1' }}>
            {pi + 1}
          </button>
        ),
      };
      p.email = {
        value: p.players[0].email,
        label: p.players.map((pl) => (
          <span key={`pl-${pl._id}`} className='d-block'>
            {`${pl.name} ${pl.lastname}`.trim()
              ? `${pl.name} ${pl.lastname}`
              : pl.email}
          </span>
        )),
      };
      p.band = accepted
        ? accepted.band >= (accepted.band + accepted.finish) / 2
          ? {
              label: <span className='text-danger'>{accepted.band}</span>,
              value: accepted.band,
            }
          : accepted.band
        : p.status === 'nodeal'
        ? { value: 'No Deal', label: 'No Deal' }
        : { value: '-', label: '-' };
      p.finish = accepted
        ? accepted.finish
        : p.status === 'nodeal'
        ? 'No Deal'
        : '-';
      p.total = accepted
        ? accepted.band + accepted.finish
        : p.status === 'nodeal'
        ? 'No Deal'
        : '-';
      p.spend = accepted ? (
        p.total * 300 > 4500 ? (
          <span className='text-danger'>
            {numeral(p.total * 300).format('0,0')}
          </span>
        ) : (
          numeral(p.total * 300).format('0,0')
        )
      ) : p.status === 'nodeal' ? (
        'No Deal'
      ) : (
        '-'
      );

      p.points0 = accepted ? (
        points0 < 60 ? (
          <span className='text-danger'>{points0}</span>
        ) : (
          points0
        )
      ) : p.status === 'nodeal' ? (
        'No Deal'
      ) : (
        '-'
      );
      p.points1 = accepted ? (
        points1 < 40 ? (
          <span className='text-danger'>{points1}</span>
        ) : (
          points1
        )
      ) : p.status === 'nodeal' ? (
        'No Deal'
      ) : (
        '-'
      );

      p.aspiration0 = player0 && player0.aspiration ? player0.aspiration : '-';
      p.aspiration1 = player1 && player1.aspiration ? player1.aspiration : '-';

      p.reservation0 =
        player0 && player0.reservation ? player0.reservation : '-';
      p.reservation1 =
        player1 && player1.reservation ? player1.reservation : '-';

      p.satisfaction0 =
        player0 && player0.satisfaction ? player0.satisfaction : '-';
      p.satisfaction1 =
        player1 && player1.satisfaction ? player1.satisfaction : '-';

      p.group_prep = `${
        player0?.group_prep !== null
          ? player0?.group_prep
            ? 'Yes'
            : 'No'
          : '-'
      } / ${
        player1?.group_prep !== null
          ? player1?.group_prep
            ? 'Yes'
            : 'No'
          : '-'
      }`;

      return p;
    });

    groups = groups.filter((g) => g);
    // console.log(this.props)

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Pair',
              'Learners',
              'Bandstand Booths',
              'Finish Booths',
              'Total Booths',
              'Total Spend ($)',
              `${roleNames[0]} Points`,
              `${roleNames[1]} Points`,
              `${roleNames[0]} Aspiration`,
              `${roleNames[1]} Aspiration`,
              `${roleNames[0]} Reservation`,
              `${roleNames[1]} Reservation`,
              // `${roleNames[0]} Satisfaction`, `${roleNames[1]} Satisfaction`
              <span>
                Group Prep{' '}
                <span>
                  {roleNames[0]}/{roleNames[1]}
                </span>
              </span>,
            ]}
            fields={[
              'group',
              'email',
              'band',
              'finish',
              'total',
              'spend',
              'points0',
              'points1',
              'aspiration0',
              'aspiration1',
              'reservation0',
              'reservation1',
              // 'satisfaction0', 'satisfaction1'
              'group_prep',
            ]}
            sortables={[
              'group',
              'email',
              'band',
              'finish',
              'total',
              'spend',
              'points0',
              'points1',
              'aspiration0',
              'aspiration1',
              'reservation0',
              'reservation1',
              // 'satisfaction0', 'satisfaction1'
              'group_prep',
            ]}
            data={groups}
            onRowClick={this.rowClick}
          />
        </div>

        <Modal
          isOpen={this.state.group ? true : false}
          centered={true}
          size='xxl'
          toggle={() => this.setState({ group: null })}>
          <ModalBody className='text-center'>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size='2x'
              className='icon bg-white text-danger'
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                borderRadius: '50%',
              }}
              onClick={() => this.setState({ group: null })}
            />

            {this.state.group &&
              this.state.group.players.length === game.data.ppg && (
                <GroupDebrief group={this.state.group} />
              )}
            {this.state.group &&
              this.state.group.players.length !== game.data.ppg && (
                <p className='mb-0'>Incomplete pair. No debrief available.</p>
              )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
