import { gamesConstants } from '../constants';

export const gamesActions = {
    fetchGames,
    removeGame
};

// New methods
function fetchGames( id ) {
    return dispatch => {
        fetch(process.env.REACT_APP_API_URL + '/games/' + (id ? id : '') )
        .then( res => res.json() )
        .then( res => {
            if( Array.isArray(res) )
                dispatch({ type: gamesConstants.SET_GAMES, data: res })
            else
                dispatch({ type: gamesConstants.SET_GAME, data: res })
        })
    };
}

function removeGame( data ) {
    return dispatch => {
        fetch(process.env.REACT_APP_API_URL + '/games/' + data._id + '/delete', {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then( res => res.json() )
        .then( res => {
            setTimeout(() => dispatch({ type: gamesConstants.REMOVE_ITEM, data: res }), 250);
        })
    };
}